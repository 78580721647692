<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab
        :title-link-class="'icon-panel-home'"
        @click="openMenuPanel"
      >
        <template #title>
          <feather-icon
            icon="ArrowLeftIcon"
            size="19"
          />
        </template>
      </b-tab>
      <b-tab
        title="Gestão de Contratos"
        active
      >
        <iframe
          style="border:0;"
          width="100%"
          height="800"
          src="https://app.powerbi.com/view?r=eyJrIjoiNzNiZDZmNGUtYjUwZC00MjhlLTkyZjQtMDdiMmU1YjM5YTMwIiwidCI6Ijg1NDczOTk4LTFmODEtNDAxMS1iYzk3LTg3YWUwNGU2MTIwNCJ9"
          frameborder="0"
          allowFullScreen="true"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'

export default {
  data() {
    return {}
  },
  beforeCreate() {
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'hidden' })
  },
  beforeRouteLeave(to, from, next) {
    store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'floating' })
    next()
  },
  methods: {
    openMenuPanel() {
      router.push({ name: 'panel' })
    },
  },
}
</script>
<style>
  [dir] .nav-pills .nav-link {
    padding: 0.3rem 1.5rem;
    border: 1px solid transparent;
  }
  .icon-panel-home {
    padding: 0.1rem 0.8rem 0rem 0rem !important
  }
</style>
